import {
	LitElement,
	html,
} from 'lit';

/** @typedef {import("./vi-cart").default} ViCart */

const translations = JSON.parse(document.getElementById('api-translations')?.innerText ?? '{}');

class ViWishlist extends LitElement {
	apiUrl = '/api/wishlist';

	/** button with heart icon in .m-navigation */
	buttonToggleWishlistElement;

	drawerElement;

	constructor() {
		super();

		const savedWishlist = document.querySelector('vi-wishlist[page]');
		if (savedWishlist && savedWishlist !== this) {
			this.savedWishlist = savedWishlist;
			this.addEventListener('change', () => {
				// update saved wishlist
				if (
					savedWishlist.wishlistPageUId === this.wishlistPageUId
					&& this.data?.productList
					&& savedWishlist.data?.productList
				) {
					const newData = savedWishlist.data;
					newData.productList = this.data.productList;
					savedWishlist.data = newData;
					savedWishlist.requestUpdate();
				}
			});
		}
	}

	static properties = {
		data: { type: Object },
		variant: { type: String }, // active (inside of .m-drawer-wishlist) or page (for wishlist page)
		page: { type: String }, // page id of wishlist page (used for variant="page" – stored wishlist)
	};

	updated() {
		if (this.dataLoaded === true) {
			this.buttonToggleWishlistElement?.toggleAttribute('hidden', this.items.length === 0);
			this.buttonToggleWishlistElement?.toggleAttribute('data-has-items', this.items.length > 0);

			// Additional set local storage, which is read by inline script in m-navigation.php. This avoids flickering the wishlist icon.
			localStorage.setItem('showWishlist', this.items.length > 0);
		}
		this.dispatchEvent(new CustomEvent('change'));
	}

	/** @returns {array} */
	get items() {
		return this.data?.productList?.items ?? [];
	}

	get sum() {
		return this.data?.productList?.sum;
	}

	get wishlistPageUId() {
		return this.data?.wishlistPage?.uid;
	}

	get wishlistPageId() {
		return this.data?.wishlistPage?.id;
	}

	get wishlistPageUrl() {
		return this.data?.wishlistPage?.url;
	}

	get wishlistPageShortUrl() {
		if (this.wishlistPageUrl) {
			const url = new URL(this.wishlistPageUrl);
			return `${url.hostname}${url.pathname}`;
		}
		return '';
	}

	get dataLoaded() {
		return !!this.data?.productList;
	}

	get requestFormFormElement() {
		return this.querySelector('[slot="request-form"] form');
	}

	async request(endpoint = '', method = 'get', body = null) {
		const {
			apiUrl,
		} = this;

		const url = new URL(`${apiUrl}/${endpoint}`, window.location.origin);

		if (this.page) {
			url.searchParams.append('pageId', this.page);
		}

		const responseData = await fetch(url, {
			method,
			body,
			headers: {
				'x-language': document.documentElement.lang,
			},
		})
			.then((response) => response.json())
			.catch((error) => {
				// Handle any errors
				console.error(error);
			});

		if (responseData.status === 'ok') {
			this.data = responseData.data;
		} else {
			// Handle response errors
			alert(responseData?.message ?? 'Unkown error');
			console.error(responseData?.message ?? 'Unkown error');
		}

		return responseData;
	}

	async loadData() {
		await this.request();
	}

	async addItem(id, quantity = 1, currency = null, data = null) {
		const formData = new FormData();
		formData.append('id', id);
		formData.append('quantity', quantity);
		formData.append('currency', currency);
		if (typeof data === 'object') {
			formData.append('data', JSON.stringify(data));
		}

		const responseData = await this.request('add', 'post', formData);

		return responseData;
	}

	async addItemsToCart() {
		/** @type {ViCart} */
		const cartElement = document.querySelector('vi-cart');

		/** Cart Response data */
		const responseData = await cartElement.addItems(this.items, this.page);

		const isCartDrawerOpen = cartElement.open();

		if (isCartDrawerOpen === false) {
			/** Cart drawer does not exists => checkout page. */
			this.drawerElement.dispatchEvent(new Event('click'));
		}

		return responseData;
	}

	async updateItem(key, quantity, data = null) {
		const formData = new FormData();
		formData.append('key', key);
		formData.append('quantity', quantity);
		if (typeof data === 'object') {
			formData.append('data', JSON.stringify(data));
		}

		await this.request('update', 'post', formData);
	}

	async removeItem(key) {
		const formData = new FormData();
		formData.append('key', key);

		await this.request('remove', 'post', formData);
	}

	async store() {
		await this.request('store?view=compact', 'post');
	}

	async unlink() {
		await this.request('unlink?view=compact', 'post');
	}

	async clear() {
		await this.request('clear?view=compact', 'post');
	}

	open() {
		this.drawerElement.showModal();
	}

	async #onItemUpdate(event) {
		const element = event.target;
		const {
			key,
			quantity,
		} = element;
		element.loading = true;
		await this.updateItem(key, quantity);
		element.loading = false;
	}

	#onItemRemove(event) {
		const {
			key,
		} = event.target;
		this.removeItem(key);
	}

	#onItemAddedToCart() {
		/** @type {ViCart} */
		const cartElement = document.querySelector('vi-cart');
		const isCartDrawerOpen = cartElement.open();

		if (isCartDrawerOpen === false) {
			/** Cart drawer does not exists => checkout page. */
			this.drawerElement.dispatchEvent(new Event('click'));
		}
	}

	async #onButtonStoreClick() {
		await this.store();
		this.#showSavedDialog();
	}

	async #onButtonRequestClick() {
		if (!this.wishlistPageUrl) {
			// store wishlist, if it is not already stored.
			await this.store();
		}
		this.#showRequestDialog();
	}

	async #onButtonAddAllToCartClick() {
		await this.addItemsToCart();
	}

	#onButtonUnlinkClick() {
		this.unlink();
	}

	#onButtonClearClick() {
		this.clear();
	}

	#onButtonPrintClick() {
		window.print();
	}

	async onButtonShareClick() {
		try {
			if (!this.wishlistPageUrl) {
				// store wishlist, if it is not already stored.
				await this.store();
			}
			await navigator.share({
				title: translations['wishlist.saved.share-title'],
				url: this.wishlistPageUrl,
			});
		} catch (error) {
			console.info(error);
		}
	}

	async onButtonCopyLinkClick(event) {
		const mToastsElement = event.target.closest('vi-dialog')?.querySelector('.m-toasts');
		try {
			await navigator.clipboard.writeText(this.wishlistPageUrl);
			if (mToastsElement) {
				const toast = Object.assign(document.createElement('vi-toast'), {
					innerText: translations['saved-to-clipboard'],
				});
				toast.toggleAttribute('inverse', true);
				mToastsElement.prepend(toast);
			}
		} catch (error) {
			console.error(error);
		}
	}

	onButtonContinueClick(event) {
		try {
			event.target.closest('vi-dialog').close();
		} catch (error) {
			console.info(error);
		}
	}

	#showSavedDialog() {
		const dialogElement = this.renderRoot.querySelector('#save-wishlist');
		if (dialogElement) {
			dialogElement.showModal();
		}
	}

	#showRequestDialog() {
		const dialogElement = this.shadowRoot.querySelector('#request-wishlist');

		const formElement = this.requestFormFormElement;

		if (formElement.elements.namedItem('page-id') === null) {
			const hiddenInputElement = Object.assign(document.createElement('input'), {
				name: 'page-id',
				value: this.wishlistPageId,
				type: 'hidden',
			});
			formElement.appendChild(hiddenInputElement);
		}

		if (dialogElement) {
			dialogElement.showModal();
		}
	}

	connectedCallback() {
		super.connectedCallback();
		this.loadData();
		if (this.variant === 'active') {
			this.drawerElement = this.closest('.m-drawer-wishlist');
			this.buttonToggleWishlistElement = document.querySelector('.a-button[data-action="toggle-wishlist"]');
		}
	}

	render() {
		const {
			items = [],
		} = this;

		return html`
			<link rel="stylesheet" href="/assets/css/vi-wishlist.${BUILT_AT}.css">
			<section class="content">
				<div>
					${this.variant === 'active' ? html`
						<header class="header">
							<h2>${translations['wishlist.headline']}</h2>
							${this.wishlistPageUId && this.wishlistPageUrl ? html`
								<div class="page-id">
									ID:
									<a class="a-button" data-shape="text" data-size="small" href="${this.wishlistPageUrl}">
										<span>${this.wishlistPageUId}</span>
									</a>
								</div> ` : null}
						</header>
						` : null}
					<div class="m-banner" data-theme="inverse" ?hidden=${this.items.length !== 0}>
						${translations['wishlist.empty.notice']}
					</div>
					<div class="product-list">
						${items?.map((item) => html`
							<vi-product-list-item
								.readonly=${this.page}
								.id=${item.id}
								.key=${item.key}
								.productImage=${item.productImage}
								.url=${item.url}
								.productTitle=${item.title}
								.subtitle=${item.subtitle}
								.articleNumber=${item.articleNumber}
								.color=${item.color}
								.size=${item.size}
								.price=${item.price}
								.sum=${item.sum}
								.quantity=${item.quantity}
								.length=${item.length}
								.option=${item.option}
								.maxAmount=${item.maxAmount}
								.system=${item.system}
								.configuration=${item.configuration}
								.packageContents=${item.packageContents}
								.data=${item.data}
								.variant=${'wishlist'}
								@update=${this.#onItemUpdate}
								@remove=${this.#onItemRemove}
								@addedToCart=${this.#onItemAddedToCart}
							>
						`)}
					</div>
				</div>
			</div>
			${this.items.length > 0 || this.wishlistPageUrl ? html`
				<div class="footer" ?hidden=${this.items.length === 0}>
					${this.items.length > 0 ? html`<small>${translations['wishlist.price-notice']}</small>` : ''}
					<div class="sum" ?hidden=${this.items.length === 0}>
						<strong>${this.sum}</strong>
						<small>${translations['wishlist.excl-tax']}</small>
					</div>
				</div>
				<div class="buttons">
					<div class="m-stack" data-direction="column" data-size="small">
						<div class="m-stack" data-size="small">
							<button
								class="a-button"
								data-kind="primary"
								data-size="medium"
								?hidden=${this.items.length === 0}
								@click=${this.#onButtonRequestClick}
							>
								${translations['wishlist.request']}
							</button>
							<vi-dialog id="request-wishlist" size="medium">
								<span slot="header">${translations['wishlist.request.headline']}</span>
								<slot name="request-form"></slot>
								<slot slot="primary-button" name="request-form-button"></slot>
							</vi-dialog>
							<button
								class="a-button"
								data-kind="secondary"
								data-size="medium"
								?hidden=${this.items.length === 0}
								@click=${this.#onButtonAddAllToCartClick}
							>
								${translations['wishlist.add-all-to-cart']}
							</button>
						</div>
						<div class="m-stack" data-size="small">
							${typeof navigator.share === 'function' ? html`
								<button
									class="a-button"
									data-kind="secondary"
									data-size="medium"
									?hidden=${this.items.length === 0}
									@click=${this.onButtonShareClick}
								>
									${translations['wishlist.share']}
								</button>
							` : null}
							${!(this.wishlistPageUrl || this.items.length === 0) ? html`
								<button
									class="a-button"
									data-kind="secondary"
									data-size="medium"
									?hidden=${this.wishlistPageUrl || this.items.length === 0}
									@click=${this.#onButtonStoreClick}
								>
									${translations['wishlist.save']}
								</button>
							` : null}
							<vi-dialog id="save-wishlist" size="medium">
								<span slot="header">${translations['wishlist.saved.headline']}</span>
								<div class="a-text">
									<p>${translations['wishlist.saved.text']}</p>
									<p><strong><a href="${this.wishlistPageUrl}">${this.wishlistPageShortUrl}</a></strong></p>
								</div>
								<div class="m-toasts" role="log" aria-live="polite"></div>
								<div slot="secondary-buttons">
									${typeof navigator.share === 'function' ? html`
										<button
											class="a-button"
											data-kind="secondary"
											@click=${this.onButtonShareClick}
										>
											${translations.share}
										</button>
									` : null}
									<button
										class="a-button"
										data-kind="secondary"
										@click=${this.onButtonCopyLinkClick}
									>
										${translations['copy-link']}
									</button>
								</div>
								<div slot="primary-button">
									<button class="a-button" data-kind="primary" data-size="medium" @click=${this.onButtonContinueClick}>
										${translations.continue}
									</button>
								</div>
							</vi-dialog>
							${this.variant === 'active' ? html`
								<button
									class="a-button"
									data-kind="secondary"
									data-size="medium"
									?hidden=${!this.wishlistPageUrl || this.items.length === 0}
									@click=${this.#onButtonUnlinkClick}
								>
									${translations['wishlist.unlink']}
								</button>
								<button
									class="a-button"
									data-kind="secondary"
									data-size="medium"
									@click=${this.#onButtonClearClick}
								>
									${translations['wishlist.clear']}
								</button>
							` : html`
								<a
									class="a-button"
									data-kind="secondary"
									data-size="medium"
									?hidden=${this.items.length === 0}
									href="${document.location.origin}${document.location.pathname}.csv"
								>
									${translations['wishlist.download']}
								</a>
								<button
									class="a-button"
									data-kind="secondary"
									data-size="medium"
									?hidden=${this.items.length === 0}
									@click=${this.#onButtonPrintClick}
								>
									${translations['wishlist.print']}
								</button>
							`}
						</div>
					</div>
				</section>
			` : ''}
		`;
	}
}

// Define the custom element
if (!customElements.get('vi-wishlist')) {
	customElements.define('vi-wishlist', ViWishlist);
}

export default ViWishlist;
