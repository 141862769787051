import {
	LitElement,
	html,
	nothing,
} from 'lit';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import icons from '../foundation/icons.js';

const translations = JSON.parse(document.getElementById('api-translations')?.innerText ?? '{}');

const loadingIconInStack = `<div class="m-stack" data-justify="end"><span class="a-icon" data-kind="loader" data-size="small">${icons.loader}</span></div>`;

class ViCart extends LitElement {
	apiUrl = '/api/cart';

	/** button with heart icon in .m-navigation */
	buttonToggleCartElement;

	drawerElement;

	static properties = {
		data: { type: Object },
		loadingDiscount: { type: Boolean },
		loadingShipping: { type: Boolean },
		loadingTax: { type: Boolean },
		variant: { type: String }, // checkout (on checkout page) or empty
	};

	constructor() {
		super();
		this.data = null;
		this.loadingDiscount = false;
		this.loadingShipping = false;
		this.loadingTax = false;
	}

	updated() {
		this.buttonToggleCartElement?.toggleAttribute('data-has-items', this.items.length > 0);
		this.dispatchEvent(new CustomEvent('change'));
	}

	get items() {
		return this.data?.items ?? [];
	}

	get sum() {
		return this.data?.sum;
	}

	get dataLoaded() {
		return !!this.data;
	}

	get requestFormFormElement() {
		return this.querySelector('[slot="request-form"] form');
	}

	async request(endpoint = '', method = 'get', body = null, signal = null) {
		const {
			apiUrl,
		} = this;

		const url = new URL(`${apiUrl}/${endpoint}`, window.location.origin);

		const responseData = await fetch(url, {
			method,
			body,
			headers: {
				'x-language': document.documentElement.lang,
			},
			signal,
		})
			.then((response) => response.json())
			.catch((error) => {
				// Handle any errors
				console.error(error);
			});

		if (responseData.status === 'ok') {
			this.data = responseData.data;
		} else {
			// Handle response errors
			const error = responseData?.message ?? 'Unkown error';
			console.error(error);
			const toast = Object.assign(document.createElement('vi-toast'), {
				innerHTML: error,
			});
			toast.setAttribute('theme', 'negative');
			document.querySelector('.m-toasts')?.prepend(toast);
			return new Error(error);
		}

		return responseData;
	}

	async loadData() {
		await this.request();
	}

	async addItem(id, quantity = 1, currency = null, data = null) {
		const formData = new FormData();
		formData.append('id', id);
		formData.append('quantity', quantity);
		formData.append('currency', currency);
		if (typeof data === 'object') {
			formData.append('data', JSON.stringify(data));
		}

		const responseData = await this.request('add', 'post', formData);

		return responseData;
	}

	/** @param {array} items */
	async addItems(items, savedWishlist) {
		const formData = new FormData();
		formData.append('items', JSON.stringify(items));
		let endpoint = 'add?multiple=true';
		if (savedWishlist) {
			endpoint += `&wishlist=${savedWishlist}`;
		}
		return this.request(endpoint, 'post', formData);
	}

	async updateItem(key, quantity, data = null) {
		const formData = new FormData();
		formData.append('key', key);
		formData.append('quantity', quantity);
		if (typeof data === 'object') {
			formData.append('data', JSON.stringify(data));
		}

		await this.request('update', 'post', formData);
	}

	async removeItem(key) {
		const formData = new FormData();
		formData.append('key', key);

		await this.request('remove', 'post', formData);
	}

	async updateCountry(countryCode, signal = null) {
		const formData = new FormData();
		formData.append('country', countryCode);

		this.loadingTax = true;
		this.loadingShipping = true;

		await this.request('country/update', 'post', formData, signal);

		this.loadingTax = false;
		this.loadingShipping = false;
	}

	async setDiscount(discountCode) {
		const formData = new FormData();
		formData.append('discount_code', discountCode);

		this.loadingDiscount = true;

		const response = await this.request('discount/add', 'post', formData);

		this.loadingDiscount = false;

		return response;
	}

	async removeDiscount() {
		this.loadingDiscount = true;

		await this.request('discount/remove', 'post');

		this.loadingDiscount = false;
	}

	async updatePostalCode(postalCode, signal = null) {
		const formData = new FormData();
		formData.append('postalCode', postalCode);

		this.loadingTax = true;
		this.loadingShipping = true;

		await this.request('postal-code/update', 'post', formData, signal);

		this.loadingTax = false;
		this.loadingShipping = false;
	}

	/** Returns false, when drawer element does not exist. */
	open() {
		if (this.drawerElement) {
			this.drawerElement?.showModal();
			return true;
		}
		return false;
	}

	async #onItemUpdate(event) {
		const element = event.target;
		const {
			key,
			quantity,
		} = element;
		element.loading = true;
		await this.updateItem(key, quantity);
		element.loading = false;
	}

	#onItemRemove(event) {
		const {
			key,
		} = event.target;
		this.removeItem(key);
	}

	async #onButtonClearClick() {
		await this.request('clear');
	}

	connectedCallback() {
		super.connectedCallback();
		this.loadData();
		this.buttonToggleCartElement = document.querySelector('.a-button[data-type="cart"]');
		if (this.variant === 'active') {
			this.drawerElement = this.closest('.m-drawer-cart');
		}
	}

	renderShipping() {
		const { data, loadingShipping } = this;

		if (loadingShipping) {
			return html`
				<tr>
					<th>
						${translations['cart.shipping']}
					</th>
					<td>${unsafeHTML(loadingIconInStack)}</td>
				</tr>
			`;
		}
		if (data.shipping === null) {
			return html`
				<tr>
					<th colspan="2">
						${translations['cart.shipping.info']}
					</th>
				</tr>
			`;
		}
		return html`
			<tr>
				<th>
					${translations['cart.shipping']} (${data.shipping.country}${data.shipping.postalCode ? `, ${data.shipping.postalCode}` : null})
				</th>
				<td>+ ${data.shipping.priceNet}</td>
			</tr>
		`;
	}

	renderTax() {
		const { data, loadingTax } = this;

		if (loadingTax) {
			return html`<tr><th>${translations['cart.tax']}</th><td>${unsafeHTML(loadingIconInStack)}</td></tr>`;
		}
		if (data.taxRates.length === 0) {
			return html`<tr><th colspan="2">${translations['cart.tax.info']}</th></tr>`;
		}
		return data.taxRates.map((taxRate) => html`
			<tr><th>${translations['cart.tax']} (${taxRate.taxRate}%)</th><td>+ ${taxRate.sum}</td></tr>`);
	}

	render() {
		const {
			items = [],
			data,
			variant,
		} = this;

		return html`
			<link rel="stylesheet" href="/assets/css/vi-cart.${BUILT_AT}.css">
			<section class="content">
				<div>
					${this.variant === 'active' ? html`
						<header class="header">
							<h2>${translations['cart.headline']}</h2>
						</header>
						` : null}
					<div class="m-banner" data-theme="inverse" ?hidden=${this.items.length !== 0}>
						${translations['cart.empty.notice']}
					</div>
					<div class="product-list">
						${items?.map((item) => html`
							<vi-product-list-item
								.readonly=${this.page}
								.key=${item.key}
								.productImage=${item.productImage}
								.url=${item.url}
								.productTitle=${item.title}
								.subtitle=${item.subtitle}
								.articleNumber=${item.articleNumber}
								.color=${item.color}
								.size=${item.size}
								.price=${item.priceNet}
								.sum=${item.sumNet}
								.quantity=${item.quantity}
								.length=${item.length}
								.option=${item.option}
								.maxAmount=${item.maxAmount}
								.configuration=${item.configuration}
								.system=${item.system}
								.packageContents=${item.packageContents}
								@update=${this.#onItemUpdate}
								@remove=${this.#onItemRemove}
							>
						`)}
					</div>
				</div>
				${this.items.length > 0 ? html`
					<div class="footer" data-sticky="${variant === 'checkout' ? null : nothing}">
						<table class="a-table-sum">
							<tbody>
								<tr><th>${translations['cart.subtotal']}</th><td>${data.subtotalNet}</td></tr>
								${data.discount ? html`
									<tr>
										<th>${translations['cart.discount']} ${data.discount.discount.toLocaleString()}% (${translations['field.discount_code']}: ${data.discount.discountCode})</th>
										<td>${data.discount.priceNet}</td>
									</tr>
								` : null}
								${this.renderShipping()}
							</tbody>
							${data.taxRates.length > 0 ? html`
								<tbody data-kind="secondary">
									<tr><th>${translations['cart.net']}</th><td>${this.loadingShipping || this.loadingDiscount ? unsafeHTML(loadingIconInStack) : data.sumNet}</td></tr>
									${this.renderTax()}
								</tbody>
							` : null}
							<tfoot>
								<tr>
									<th>${translations['cart.sum']}</th>
									<td>${(this.loadingShipping || this.loadingTax || this.loadingDiscount) ? unsafeHTML(loadingIconInStack) : this.sum}</td>
								</tr>
							</tfoot>
						</table>
					</div>
				${variant !== 'checkout' ? html`
					<div class="buttons">
						<div class="m-stack" data-size="small">
							<a
								class="a-button"
								data-kind="primary"
								href="${data.checkoutUrl}"
								data-size="compact"
							>
								${translations['cart.to-checkout']}
							</a>
							<button
								class="a-button"
								data-kind="secondary"
								data-size="compact"
								@click=${this.#onButtonClearClick}
							>
								${translations['cart.clear']}
							</button>
						</div>
					</div>` : null}
				</div>
			</section>
			` : ''}
		`;
	}
}

// Define the custom element
if (!customElements.get('vi-cart')) {
	customElements.define('vi-cart', ViCart);
}

export default ViCart;
