import { html, render } from 'lit';
import { ref, createRef } from 'lit/directives/ref.js';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import Configurator from '../libraries/roomle-configurator';
import icons from '../foundation/icons.js';

/**
 * @typedef {import("@roomle/embedding-lib/types/src/configurator/embedding/roomle-configurator-api").RoomleConfiguratorApi} RoomleConfiguratorApi
 * @typedef {import("@roomle/embedding-lib/types/src/configurator/embedding/roomle-configurator-api").RoomlePlannerApi} RoomlePlannerApi
 * @typedef {import("@roomle/web-sdk/lib/definitions/typings/kernel").UiPlanObject} UiPlanObject
 * @typedef {import("@roomle/web-sdk/lib/definitions/typings/kernel").KernelPartList} KernelPartList
 * @typedef {import("@roomle/web-sdk/lib/definitions/typings/kernel").KernelPart} KernelPart
 * @typedef {import("../custom-elements/vi-cart").default} ViCart
 */

const translations = JSON.parse(document.getElementById('api-translations')?.innerText ?? '{}');

class ORoomleConfigurator {
	/** @type {RoomleConfiguratorApi|RoomlePlannerApi} */
	#configurator = null;

	#language = document.documentElement.lang;

	#buttonsElement;

	#buttonAddToCartElement;

	/** @type UiPlanObject[] - productList is assign additional to each object (async) */
	objects = [];

	/** @type [] - product list stored by configurationHash */
	cachedProductLists = [];

	constructor(element) {
		this.element = element;
		this.oRoomlePlanElement = element.querySelector('.o-roomle-plan');
		const dataConfigurator = JSON.parse(element.dataset.configurator);

		this.#buttonsElement = element.querySelector('.o-roomle-configurator__buttons');
		this.#buttonAddToCartElement = element.querySelector('button[data-action="add-to-cart"]');

		/**
		 * Formats a length in millimeters as a
		 * human-readable string in cm
		 * @param {Number} millimeters
		 * @returns {string} in cm
		 */
		this.formatLength = (millimeters) => `${(parseFloat(millimeters) / 10).toLocaleString(this.#language)} cm`;

		/**
		 * @param {Number} price
		 * @param {String} currency – default 'EUR'
		 * @returns {string} in cm
		 */
		this.formatPrice = (price, currency = 'EUR') => price.toLocaleString(this.#language, {
			style: 'currency',
			currency,
		});

		this.init(dataConfigurator);
	}

	/** @returns {string} Formatted sum */
	get formattedSum() {
		/** @property {UiPlanObject[]} objects */
		const { objects } = this;
		const { formatPrice } = this;
		let sum = 0;
		objects.forEach((object) => {
			sum += object?.productList?.sumFloat ?? 0;
		});

		if (this.isPriceUponRequest || sum === 0) {
			return `<strong>${translations['product.priceUponRequest']}</strong> (${translations['wishlist.price-from']} ${formatPrice(sum)})`;
		}
		return `<strong>${formatPrice(sum)}</strong>`;
	}

	get isPriceUponRequest() {
		const { objects } = this;

		let isPriceUponRequest = false;
		objects.forEach((object) => {
			if (object?.productList?.sumFloat === null
				|| object?.productList?.isPriceUponRequest === true) {
				isPriceUponRequest = true;
			}
		});

		return isPriceUponRequest;
	}

	/**
	 * Returns the list of items with duplicates
	 * merged to a single item including the count
	 *
	 * @see https://github.com/lukasbestle/kirby-roomle/blob/86841b7319b1b00353530d6bef6b80d192c8ae16/src/classes/Plan.php#L112
	 * @returns {Array}
	 */
	get groupedObjects() {
		const groupedObjects = {};
		this.objects.forEach((object) => {
			if (groupedObjects[object.configurationHash]) {
				groupedObjects[object.configurationHash].count += 1;
			} else {
				groupedObjects[object.configurationHash] = object;
				groupedObjects[object.configurationHash].count = 1;
			}
		});
		return Object.values(groupedObjects);
	}

	/**
	 * Set object into objects. And asynchronously fetch product list.
	 * @param {UiPlanObject} paramObject
	 */
	updateObject(uiPlanObject) {
		console.log(uiPlanObject);
		return new Promise(async (resolve) => {
			const key = uiPlanObject.runtimeId;

			this.objects[key] = uiPlanObject;

			// fetch product list
			const { parts, configurationHash } = uiPlanObject;
			if (parts.fullList.length > 0) {
				const response = await this.fetchProductList(parts.fullList, configurationHash);
				const productList = response?.data;
				if (productList) {
					Object.assign(this.objects[key], {
						productList,
					});
					this.updateButtons();
				}
			}
			resolve();
		});
	}

	/**
	 * Called when an element is changed in the plan,
	 * not called when wall or construction element changed
	 * @param {Enumify<{ ADDED: "added" = 'added'; CHANGED: "changed" = 'changed';
	           REMOVED: "removed" = 'removed' }>} changeType
	 * @param {UiPlanObject} changedObject
	 * @see https://docs.roomle.com/web/embedding/api/classes/exposed_callbacks.ExposedCallbacks.html#onplanelementchanged
	 */
	async #onPlanElementChanged(changeType, changedObject) {
		/** @type {UiPlanObject[]} */
		const objects = await this.#configurator.extended.getObjects();

		// Load object with partList, because changedObject.parts.fullList is empty.
		const changedObjectWithPartList = objects.find((_) => _.runtimeId === changedObject.runtimeId);

		console.log(`Change type: ${changeType}`, 'changedObject.parts.fullList', changedObject.parts.fullList);

		if (changeType === 'added') {
			await this.updateObject(changedObjectWithPartList);
		} else if (changeType === 'removed') {
			this.objects.splice(changedObject.runtimeId, 1);
		} else {
			await this.updateObject(changedObjectWithPartList);
		}

		this.renderRoomlePlan();
	}

	/**
	 * called when triggerSaveDraft called
	 * @param {string} id  configuration id or plan id
	 * @param {string} image image of the current configuration
	 * @param {string} url generated url from save draft
	 * @param {SaveDraftPayload} data object contains { type: 'plan' | 'configuration', payload: configuration object or plan snapshot data}
	 * @see https://docs.roomle.com/web/embedding/api/classes/exposed_callbacks.ExposedCallbacks.html#onsavedraft
	 */
	#onSaveDraft(id, image, url, data) {
		console.log('SAVEDSAVEDSAVEDSAVEDSAVEDSAVEDSAVEDSAVED');
		this.showSaveDialog(url);

		if (window.plausible) {
			plausible('Saved Roomle Configuration', {props: {roomleUrl: url}})
		}
	}

	async addItemsToCart() {
		/** @type {ViCart} */
		const cartElement = document.querySelector('vi-cart');

		const items = this.objects.reduce((accumulator, currentValue) => [
			...accumulator,
			...currentValue.productList.items,
		], []);
		// console.log(items);

		/** Cart Response data */
		const responseData = await cartElement.addItems(items);

		const isCartDrawerOpen = cartElement.open();

		if (isCartDrawerOpen === false) {
			/** Cart drawer does not exists => checkout page. */
			this.drawerElement.dispatchEvent(new Event('click'));
		}

		return responseData;
	}

	/**
	 * @param {KernelPart[]} fullList
	 * @param {String} configurationHash The hash of the configuration string.
	 * @see https://docs.roomle.com/web/api/interfaces/typings_kernel.KernelPartList.html#fulllist
	 */
	async fetchProductList(fullList, configurationHash) {
		/* Check if productList for this configurationHash was loaded before. */
		if (this.cachedProductLists[configurationHash]) {
			return new Promise((resolve) => {
				resolve(this.cachedProductLists[configurationHash]);
			});
		}

		const json = fetch('/api/catalog/products-from-partlist?view=roomleConfigurator', {
			method: 'POST',
			headers: {
				'x-language': this.#language,
			},
			body: JSON.stringify(fullList),
		}).then((response) => response.json());

		this.cachedProductLists[configurationHash] = json;

		return json;
	}

	renderRoomlePlan() {
		// console.log('render');
		render(html`
			${this.objects.length > 0 ? html`
				<h2 class="a-heading" data-size="large">${translations['configuration-request.configuration-data']}</h2>
				<p class="o-roomle-plan__sum">${unsafeHTML(this.formattedSum)}</p>
				${[...this.groupedObjects].map((object) => this.renderRoomleConfiguration(object))}
			` : null}
		`, this.oRoomlePlanElement);
	}

	/**
	 * @param {UiPlanObject} object
	 * @see https://docs.roomle.com/web/embedding/api/classes/exposed_callbacks.ExposedCallbacks.html#onplanupdate
	 */
	renderRoomleConfiguration(object) {
		const { formatLength } = this;
		const {
			data,
			parts,
			productList,
			count = 1,
		} = object;

		if (parts.fullList.length === 0) {
			return null;
		}

		const imageSrc = data?.perspectiveImage;

		console.log('perspective image', imageSrc);

		return html`
			<div class="m-roomle-configuration" .object="${object}">
				<img class="m-roomle-configuration__image" width="1024" height="1024" src="${imageSrc ?? 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='}" loading="lazy">
				<div class="m-roomle-configuration__summary">
					<h3 class="m-roomle-configuration__heading">${count}× ${data?.label}</h3>
					<div class="m-roomle-configuration__info">
						${data?.width ? html`
							<p>${this.#language === 'de' ? `B ${formatLength(data?.width)} / H ${formatLength(data?.height)} / T ${formatLength(data?.depth)}` : `W ${formatLength(data?.width)} / H ${formatLength(data?.height)} / D ${formatLength(data?.depth)}`}</p>` : null}
						${productList?.sum ? html`
							<p>
								${productList.isPriceUponRequest === true ? html`
									<strong>${translations['product.priceUponRequest']}</strong> ${productList.sumFloat > 0 ? `(${translations['wishlist.price-from']} ${productList.sum})` : null}
								` : html`
									<strong>${productList.sum}</strong>
								`}
							</p>` : html`<div class="m-stack"><span class="a-icon" data-kind="loader">${unsafeHTML(icons.loader)}</span></div>`}
					</div>
				</div>
				${productList ? html`
					<div class="m-roomle-configuration__details">
						<details class="m-details">
							<summary>
								<div>${translations['configuration-request.partlist']}</div>
								<span class="a-icon">${unsafeHTML(icons['arrow-drop-down'])}</span>
							</summary>
							<div class="m-details__content">
								<ul class="m-product-list">
									${productList.items.map((item) => html`
										<li>
											<vi-product-list-item
												readonly="true"
												.productImage="${item.productImage}"
												productTitle="${item.title}"
												title="${item.subtitle}"
												articlenumber="${item.articleNumber}"
												.parameters="${item.parameters}"
												price="${item.price}"
												quantity="${item.quantity}"
												sum="${item.sum ?? translations['product.priceUponRequest']}"
												url="${item.url}"
											>
											</vi-product-list-item>
										</li>
									`)}
								</ul>
							</div>
						</details>
					</div>
				` : null}
			</div>
		`;
	}

	updateButtons() {
		this.#buttonsElement.toggleAttribute('hidden', !(this.objects.length > 0));
		this.#buttonAddToCartElement.toggleAttribute('hidden', this.isPriceUponRequest === true);
	}

	showSaveDialog(url) {
		const urlObject = new URL(url);
		console.log(urlObject);
		const shortUrl = `${urlObject.hostname}${urlObject.pathname}${urlObject.search}`;

		const dialogElement = createRef();

		const onButtonShareClick = async () => {
			try {
				await navigator.share({
					title: translations['configuration.share-title'],
					url,
				});
			} catch (error) {
				console.info(error);
			}
		};
		const onButtonCopyLinkClick = async () => {
			try {
				await navigator.clipboard.writeText(url);
				const toast = Object.assign(document.createElement('vi-toast'), {
					innerText: translations['saved-to-clipboard'],
				});
				toast.toggleAttribute('inverse', true);
				dialogElement.value.querySelector('.m-toasts')?.prepend(toast);
			} catch (error) {
				console.error(error);
			}
		};

		render(html`
			<vi-dialog ${ref(dialogElement)} size="medium">
				<span slot="header">${translations['configuration.save-dialog.title']}</span>
				<div class="a-text">
					<p>${translations['configuration.save-dialog.text']}</p>
					<p><strong><a href="${url}">${shortUrl}</a></strong></p>
				</div>
				<div class="m-toasts" role="log" aria-live="polite"></div>
				<div slot="secondary-buttons">
					${typeof navigator.share === 'function' ? html`
						<button
							class="a-button"
							data-kind="secondary"
							@click=${onButtonShareClick}
						>
							${translations.share}
						</button>
					` : null}
					<button
						class="a-button"
						data-kind="secondary"
						@click=${onButtonCopyLinkClick}
					>
						${translations['copy-link']}
					</button>
				</div>
			</vi-dialog>
		`, document.body);

		/** wait for dialogElement to be rendered */
		requestAnimationFrame(() => {
			dialogElement.value.showModal();
		});
	}

	async init(dataConfigurator) {
		this.#buttonAddToCartElement.toggleAttribute('hidden', true);

		const roomleConfigurator = new Configurator(dataConfigurator);
		await roomleConfigurator.init();

		this.#configurator = roomleConfigurator.configurator;

		this.#configurator.extended.callbacks.onPlanElementChanged = this.#onPlanElementChanged.bind(this);
		this.#configurator.ui.callbacks.onSaveDraft = this.#onSaveDraft.bind(this);

		console.log(this.#configurator);

		// Button events
		this.element.querySelector('button[data-action="request-plan"]')?.addEventListener('click', (event) => {
			event.target.toggleAttribute('data-loader', true);
			this.#configurator.ui.triggerRequestPlan();
		});
		this.element.querySelector('button[data-action="save-draft"]')?.addEventListener('click', async (event) => {
			event.target.toggleAttribute('data-loader', true);
			await this.#configurator.ui.triggerSaveDraft();
			event.target.toggleAttribute('data-loader', false);
		});
		this.element.querySelector('button[data-action="add-to-cart"]')?.addEventListener('click', async (event) => {
			event.target.toggleAttribute('data-loader', true);
			await this.addItemsToCart();
			event.target.toggleAttribute('data-loader', false);
		});
	}
}

document.querySelectorAll('.o-roomle-configurator').forEach((_) => new ORoomleConfigurator(_));
